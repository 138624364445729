import React from 'react';
import {AccountFragment} from 'lib/graphql/API';
import {getSignUpQueryStringKeyValue} from 'payble-shared/src/util/getSignUpQueryStringKeyValue';
import slugify from 'slugify';
import {getFormattedAccountMeta} from 'payble-shared';
import {consumerURL} from '../../../lib/url';

type AccountMetaProps = {
  account: AccountFragment;
  slug?: string;
};

export const AccountMeta: React.FC<AccountMetaProps> = ({account, slug}) => {
  const signUpQueryParams = new URLSearchParams(
    getSignUpQueryStringKeyValue({
      externalId: account.externalId,
      type: account.type,
      admin: true,
      verificationCode: account.meta?.verificationCode,
    })
  );

  const consumerDomain = new URL(consumerURL).hostname;
  const signupUrl = `${consumerURL}/biller/${slug}?${signUpQueryParams.toString()}`;
  let qrCodeUrl = `https://qr.payble.com.au?biller=${slug}&t=${account.type}&e=${account.externalId}&v=1&s=admin&hostname=${consumerDomain}`;

  if (account.meta !== null && account.meta.verificationCode) {
    qrCodeUrl = qrCodeUrl + '&a=' + account.meta.verificationCode;
  }

  return (
    <>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg ">
          <div className="px-4 py-5 sm:px-6 relative">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              QR Code
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              A QR code to enable a contact to link and manage this account.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="flex">
              <img className="h-44 w-44" src={qrCodeUrl} />
              <div className="flex-1 pl-4">
                <div>Sign Up Url</div>
                <input
                  type="text"
                  readOnly={true}
                  value={signupUrl}
                  className="bg-gray-100 w-full mt-2 text-xs text-gray-500 font-mono rounded-md border-gray-300 outline-none ring-0 focus:ring-0 focus:outline-none focus:border-gray-400"
                />
                <div className="mt-2">Image Url</div>
                <input
                  type="text"
                  readOnly={true}
                  value={qrCodeUrl}
                  className="bg-gray-100 w-full mt-2 text-xs text-gray-500 font-mono rounded-md border-gray-300 outline-none ring-0 focus:ring-0 focus:outline-none focus:border-gray-400"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {account.meta !== null && (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-8">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-8">
            <div className="px-4 py-5 sm:px-6 relative">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Meta Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Additional data which has been attached to the account, normally
                as part of an integration. Payble uses this data in
                communication templates and can trigger custom business rules
                when it changes.
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                {getFormattedAccountMeta(account.meta).map(({title, value}) => (
                  <div className="sm:col-span-1" key={slugify(title)}>
                    <dt className="text-sm font-medium text-gray-500">
                      {title}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{value}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
