import {classNames} from '../lib/styles';
import {
  NZBankAccountNumber,
  STANDARD_LENGTHS,
} from 'payble-shared/src/valueObjects/NZBankAccountNumber';
import {useEffect, useRef, useState} from 'react';

interface Props {
  onChange(value: string): void;
}

type FieldName = 'bank' | 'branch' | 'account' | 'suffix';

const containerClassName =
  'border-1 block w-full mt-1 placeholder-gray-300 border-gray-300 rounded-md shadow-sm focus:ring-blue-600 focus:border-blue-600 sm:text-sm';

const inputClassName =
  'w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-0 border-0';

const formatInput = (value: string, maxLength: number) => {
  return parseInt(value.trim().replace(/\D/g, ''))
    .toString()
    .slice(0, maxLength)
    .padStart(maxLength, '0');
};

export const DirectDebitAccountNumberInputNZ: React.FC<Props> = ({
  onChange,
}) => {
  const [bank, setBank] = useState<string>();
  const [branch, setBranch] = useState<string>();
  const [account, setAccount] = useState<string>();
  const [suffix, setSuffix] = useState<string>();
  const bankRef = useRef<HTMLInputElement>(null);
  const branchRef = useRef<HTMLInputElement>(null);
  const accountRef = useRef<HTMLInputElement>(null);
  const suffixRef = useRef<HTMLInputElement>(null);
  const refs = [bankRef, branchRef, accountRef, suffixRef];
  const touched = useRef<Record<FieldName, boolean>>({
    bank: false,
    branch: false,
    account: false,
    suffix: false,
  });

  useEffect(() => {
    if (Object.values(touched.current).every(Boolean)) {
      onChange(`${bank}-${branch}-${account}-${suffix}`);
    }
  }, [bank, branch, account, suffix, touched]);

  const handleKeyDown =
    (prevInput: React.RefObject<HTMLInputElement>) =>
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
        prevInput.current?.focus();
      }
    };

  const handleChange =
    (setValue: (value: string) => void, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      if (value.length >= e.target.maxLength) {
        const nextInput = refs[index + 1];
        if (nextInput?.current) {
          nextInput.current?.focus?.();
          nextInput.current?.select?.();
        } else {
          refs[index].current?.blur();
          refs[index].current?.focus();
        }
      }

      if (value.length > e.target.maxLength) {
        return;
      }

      setValue(value);
    };

  const handleBlur =
    (setValue: (value: string) => void, touchedKey: FieldName) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(
        e.target.value ? formatInput(e.target.value, e.target.maxLength) : ''
      );
      touched.current[touchedKey] = true;
    };

  return (
    <div className="flex flex-col gap-1">
      <div
        className={classNames(
          containerClassName,
          'flex items-center gap-0 p-0 border justify-between sm:justify-evenly md:justify-start'
        )}
        onClick={e => {
          if (!(e.target instanceof HTMLInputElement)) {
            bankRef.current?.focus();
          }
        }}
        onPaste={e => {
          const accountNumber = NZBankAccountNumber.maybeFromJSON(
            e.clipboardData.getData('text')
          );

          if (accountNumber) {
            e.preventDefault();

            const [bank, branch, account, suffix] = accountNumber
              .toJSON()
              .split('-');

            setBank(bank);
            setBranch(branch);
            setAccount(account);
            setSuffix(suffix);

            touched.current['bank'] = true;
            touched.current['branch'] = true;
            touched.current['account'] = true;
            touched.current['suffix'] = true;
          }
        }}
      >
        <input
          className={classNames(
            inputClassName,
            'w-[4ch] py-2 pl-3 pr-1 shrink-0 rounded-l-md sm:rounded-none md:rounded-l-md'
          )}
          ref={bankRef}
          type="number"
          min={0}
          max={99}
          autoComplete="off"
          placeholder={'0'.repeat(STANDARD_LENGTHS.bank)}
          value={bank}
          maxLength={STANDARD_LENGTHS.bank}
          onKeyDown={handleKeyDown(bankRef)}
          onChange={handleChange(setBank, 0)}
          onBlur={handleBlur(setBank, 'bank')}
        />
        <span className="grow-0">-</span>
        <input
          className={classNames(inputClassName, 'w-[5ch] py-2 px-1 shrink-0')}
          ref={branchRef}
          type="number"
          min={0}
          max={9999}
          autoComplete="off"
          placeholder={'0'.repeat(STANDARD_LENGTHS.branch)}
          value={branch}
          maxLength={STANDARD_LENGTHS.branch}
          onKeyDown={handleKeyDown(bankRef)}
          onChange={handleChange(setBranch, 1)}
          onBlur={handleBlur(setBranch, 'branch')}
        />
        <span className="grow-0">-</span>
        <input
          className={classNames(inputClassName, 'w-[8ch] py-2 px-1 shrink-0')}
          ref={accountRef}
          type="number"
          min={0}
          max={99999999}
          autoComplete="off"
          placeholder={'0'.repeat(STANDARD_LENGTHS.account)}
          value={account}
          maxLength={STANDARD_LENGTHS.account}
          onKeyDown={handleKeyDown(branchRef)}
          onChange={handleChange(setAccount, 2)}
          onBlur={handleBlur(setAccount, 'account')}
        />
        <span className="grow-0">-</span>
        <input
          className={classNames(
            inputClassName,
            'w-[4ch] py-2 pl-1 pr-3 shrink-0 rounded-r-md sm:rounded-none'
          )}
          ref={suffixRef}
          type="number"
          min={0}
          max={9999}
          autoComplete="off"
          placeholder={'0'.repeat(STANDARD_LENGTHS.suffix)}
          value={suffix}
          maxLength={STANDARD_LENGTHS.suffix}
          onKeyDown={handleKeyDown(accountRef)}
          onChange={handleChange(setSuffix, 3)}
          onBlur={handleBlur(setSuffix, 'suffix')}
        />
      </div>
    </div>
  );
};
