import {FormConfigField} from 'payble-shared';
import {Form, FormProps} from './Form';
import {FormFieldFactory} from './FormFieldFactory';
import React from 'react';

interface FormBuilderProps {
  id: string;
  schema: FormProps['schema'];
  defaultValues: FormProps['defaultValues'];
  onSubmit: FormProps['onSubmit'];
  fields: FormConfigField[];
  children?: React.ReactNode;
}

export const FormBuilder = ({
  id,
  schema,
  fields,
  defaultValues,
  onSubmit,
  children,
}: FormBuilderProps) => {
  return (
    <Form
      id={id}
      defaultValues={defaultValues}
      schema={schema}
      onSubmit={onSubmit}
    >
      {fields.map(field => FormFieldFactory.createField(field))}
      {children}
    </Form>
  );
};
