import {z} from 'zod';
import {intFromString} from '../../util/text';
import {FormConfig} from '../types';

export const smtp = {
  title: 'SMTP Email Config',
  configType: 'biller',

  validation: z.object({
    host: z.string().min(1, 'Host is required'),
    username: z.string().min(1, 'Username is required'),
    fromEmail: z.string().min(1, 'From Email is required'),
    password: z.string().min(1, 'Password is required'),
    port: z
      .string()
      .min(1, 'Port is required')
      .transform(intFromString)
      .pipe(z.number().int().min(1, 'Port is required'))
      .or(z.number().int().min(1, 'Port is required')),
  }),

  form: [
    {
      label: 'From Email',
      name: 'fromEmail',
      type: 'text',
      placeholder: 'rates@council.com.au',
    },
    {
      label: 'Host',
      name: 'host',
      type: 'text',
      placeholder: 'smtp.example.com',
    },
    {
      label: 'Username',
      name: 'username',
      type: 'text',
      placeholder: 'username',
    },
    {
      label: 'Password',
      name: 'password',
      type: 'password',
      placeholder: 'password',
    },
    {
      label: 'Port',
      name: 'port',
      type: 'number',
      placeholder: '587',
      description: 'Typically 587',
    },
  ],
} satisfies FormConfig;
