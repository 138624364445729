import React from 'react';
import {ChevronDoubleRightIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {PlanRequest, PlanRequestEdge} from 'lib/graphql/API';
import {Paginator} from 'components/atoms/Paginator';
import {capitalize, formatToDollars} from 'payble-shared';
import {DateTime} from 'luxon';
import classNames from 'classnames';

function reviewedAt(requestedPlan: PlanRequest) {
  if (requestedPlan.rejectedAt) {
    return DateTime.fromISO(requestedPlan.rejectedAt).toLocaleString(
      DateTime.DATE_FULL
    );
  }

  if (requestedPlan.acceptedAt) {
    return DateTime.fromISO(requestedPlan.acceptedAt).toLocaleString(
      DateTime.DATE_FULL
    );
  }

  return 'Not reviewed';
}

function RequestStatusPill({requestedPlan}: {requestedPlan: PlanRequest}) {
  const baseClasses =
    'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium';

  if (requestedPlan.acceptedAt) {
    return (
      <span
        className={classNames([baseClasses, 'bg-green-100 text-green-800'])}
      >
        Accepted
      </span>
    );
  }

  if (requestedPlan.rejectedAt) {
    return (
      <span className={classNames([baseClasses, 'bg-red-100 text-red-800'])}>
        Rejected
      </span>
    );
  }

  return (
    <span
      className={classNames([baseClasses, ' bg-yellow-100 text-yellow-800'])}
    >
      Pending
    </span>
  );
}

type PlanRequestsTableProps = {
  loading: boolean;
  error?: Error;
  planRequests: PlanRequestEdge[];
  goToPage?: (p: number) => void;
  page?: number;
  total?: number;
  onClickRequestedPlan: (requestedPlan: PlanRequest) => void;
};

export const PlanRequestsTable: React.FC<PlanRequestsTableProps> = ({
  loading,
  error,
  planRequests,
  goToPage,
  page,
  total,
  onClickRequestedPlan,
}) => {
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-2">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Requested at
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Amount
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Frequency
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Start at
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Reviewed at
            </th>

            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Request status
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            ></th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading ? (
            <tr key={'loading'}>
              <td colSpan={15} className="py-10">
                <Loading />
              </td>
            </tr>
          ) : null}
          {error ? (
            <tr key={'error'}>
              <td colSpan={15} className="pb-10">
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          ) : null}
          {!loading &&
            !error &&
            planRequests &&
            planRequests.map(planRequest => (
              <tr key={planRequest?.node?.id}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {DateTime.fromISO(
                          planRequest?.node?.createdAt
                        ).toLocaleString(DateTime.DATE_FULL)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        ${formatToDollars(planRequest.node.instalmentAmount)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {capitalize(planRequest.node.frequency)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {planRequest?.node?.startAt?.toFormat('dd MMM yyyy')}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        {reviewedAt(planRequest.node)}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="">
                      <div className="font-medium text-gray-900">
                        <RequestStatusPill requestedPlan={planRequest.node} />
                      </div>
                    </div>
                  </div>
                </td>
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                    type="button"
                    onClick={() => onClickRequestedPlan(planRequest.node)}
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-600 focus:border-blue-600"
                  >
                    <span className="sr-only">Review</span>
                    <ChevronDoubleRightIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Paginator
        loading={loading}
        rows={planRequests?.length || 0}
        goToPage={goToPage}
        page={page}
        total={total}
      />
    </div>
  );
};
