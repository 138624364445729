import {z} from 'zod';
import {isJSON} from '../../util/text';
import {FormConfig} from '../types';

export const civica = {
  title: 'Civica Integration',
  configType: 'biller',

  validation: z.object({
    accessTokenURL: z.string().url(),
    apiBaseURL: z.string().url(),
    clientId: z.string().min(1),
    clientSecret: z.string().min(1),
    memoTitle: z.string().min(1),
    appVersion: z.enum(['AUTHORITY', '71', '72']),
    ignoreCollectionExemption: z.boolean(),
    // This is a complicated struct. For now leave it as JSON and I can
    // configure it manually
    arrangements: z
      .string()
      .refine(isJSON, {
        message: 'Invalid JSON',
      })
      .optional(),
  }),

  form: [
    {
      label: 'Access Token URL',
      name: 'accessTokenURL',
      type: 'text',
      placeholder: 'https://council.gov.au/idsrv/core/connect/token',
      description:
        'This typically ends in /idsrv/core/connect/token for Authority and /main/api/v2/paymentmanagement/token for v7.1',
    },
    {
      label: 'API Base URL',
      name: 'apiBaseURL',
      type: 'text',
      placeholder: 'https://council.gov.au/api/v2',
      description: 'This typically ends in /main/api/v2',
    },
    {
      label: 'Client ID',
      name: 'clientId',
      type: 'text',
      placeholder: 'paymentmanagement_integration_client',
      description:
        'This is typically a case sensitive field. Attempt changing the case if you are having issues.',
    },
    {
      label: 'Client Secret',
      name: 'clientSecret',
      type: 'password',
    },
    {
      label: 'Memo Title',
      name: 'memoTitle',
      type: 'text',
      placeholder: 'Payble',
      description:
        'This is the name of the memo we create for accounts. Typically this is "Payble"',
    },
    {
      label: 'App Version',
      name: 'appVersion',
      type: 'dropdown',
      options: [
        {label: 'Authority (Cloud)', value: 'AUTHORITY'},
        {label: 'Self hosted v7.1', value: '71'},
        {label: 'Self hosted v7.2', value: '72'},
      ],
    },
    {
      label: 'Arrangements',
      name: 'arrangements',
      type: 'textarea',
      description:
        'This is a complex JSON field that describes what plans create arrangements. You may need help to configure this. If no arrangements are required leave this blank.',
    },
    {
      label: 'Ignore Collection Exemption',
      name: 'ignoreCollectionExemption',
      type: 'toggle',
      description:
        'If enabled this will not base collection exemption (account blocking) on the debt recovery module in the Civica API. The file "civica_collection_exemption.*.csv" will be used to mark accounts as exempt instead.',
    },
  ],
} satisfies FormConfig;
