import {FormConfigField} from 'payble-shared';
import {Form} from './Form';

const FIELD_TYPES: {
  [K in FormConfigField['type']]: (
    field: Extract<FormConfigField, {type: K}>
  ) => JSX.Element;
} = {
  text: field => (
    <Form.Input
      type="text"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  password: field => (
    <Form.Input
      type="password"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  toggle: field => <Form.Switch name={field.name} label={field.label} />,
  number: field => (
    <Form.Input
      type="number"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  date: field => (
    <Form.Input
      type="date"
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  textarea: field => (
    <Form.Textarea
      name={field.name}
      label={field.label}
      placeholder={field.placeholder}
    />
  ),
  dropdown: field => (
    <Form.Select
      name={field.name}
      label={field.label}
      options={field.options}
      aria-placeholder={field.placeholder}
    />
  ),
};

const addShared = (rendered: JSX.Element, field: FormConfigField) => {
  return (
    <div key={field.name}>
      {rendered}
      {field.description && (
        <p className="text-sm text-gray-500">{field.description}</p>
      )}
    </div>
  );
};

export const FormFieldFactory = {
  createField: (field: FormConfigField) => {
    const FieldComponent = FIELD_TYPES[field.type];

    if (!FieldComponent) {
      throw new Error(`Field type ${field.type} not found`);
    }

    return addShared(FieldComponent(field as never), field);
  },
};
