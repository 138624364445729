import {APIClient, BillerAPIClient, billerAPISpec} from 'payble-api-client';
import {makeAPIHooks} from 'payble-api-react-query';
import React, {ReactNode, useContext} from 'react';

interface APIContext {
  api: BillerAPIClient;
}

export const api = new APIClient({
  definitions: billerAPISpec,
  baseURL: '/api/v2',
});

export const Context = React.createContext<APIContext>({
  api,
});

export const Provider: React.FC<{children: ReactNode}> = ({children}) => {
  return <Context.Provider value={{api}}> {children} </Context.Provider>;
};

export const useAPI = () => {
  return useContext(Context);
};

const {useAPIMutation, useAPIQuery} = makeAPIHooks(billerAPISpec, useAPI);

export {useAPIMutation, useAPIQuery};
