import {z} from 'zod';
import {FormConfig} from '../types';

export const sinch = {
  title: 'Sinch SMS',
  configType: 'biller',

  validation: z.object({
    senderId: z.string().min(1, 'Sender ID is required'),
    apiKey: z.string().min(1, 'API Key is required'),
    apiSecret: z.string().min(1, 'API Secret is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'apiKey',
      label: 'API Key (Basic)',
      description:
        'https://support.messagemedia.com/hc/en-us/articles/4413627066383-Creating-New-API-Credentials',
    },
    {
      type: 'password',
      name: 'apiSecret',
      label: 'API Secret (Basic)',
    },
    {
      type: 'text',
      name: 'senderId',
      label: 'Sender ID',
      description: 'The numeric sender ID provisioned for the biller',
    },
  ],
} satisfies FormConfig;
