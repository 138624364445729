import {z} from 'zod';
import {isDomainName} from '../../util/text';
import {FormConfig} from '../types';

export const tokenex = {
  title: 'TOKENEX',
  configType: 'biller',

  validation: z.object({
    id: z.string().min(1, 'ID is required'),
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    apiKey: z.string().min(1, 'API Key is required'),
    iframeSecret: z.string().min(1, 'Iframe Secret is required'),
    pciHostname: z.string().min(1, 'PCI Hostname is required'),
  }),

  form: [
    {
      type: 'number',
      name: 'id',
      label: 'ID',
    },
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'apiKey',
      label: 'API key',
    },
    {
      type: 'text',
      name: 'iframeSecret',
      label: 'Iframe Secret',
    },
    {
      type: 'text',
      name: 'pciHostname',
      label: 'PCI Hostname',
    },
  ],
} satisfies FormConfig;
