import * as React from 'react';
import {cn, cva} from '../lib/utils';

export interface SelectProps
  extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  size?: 'default' | '4xl' | '2xl';
  options?: {
    value: string;
    label: React.ReactNode;
  }[];
}

const selectVariants = cva(
  'placeholder-muted-foreground caret-primary flex h-11 text-foreground-400 w-full shadow-sm rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-primary file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:border-transparent disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        default: 'font-medium',
        '2xl':
          'text-2xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
        '4xl':
          'text-4xl font-bold border-0 text-foreground shadow-none text-center focus-visible:ring-0',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  }
);

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({className, size, options, ...props}, ref) => {
    return (
      <select
        className={cn(selectVariants({size, className}))}
        ref={ref}
        {...props}
      >
        {options?.map(o => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
    );
  }
);
Select.displayName = 'Select';

export {Select, selectVariants};
