import {z} from 'zod';
import {isDomainName} from '../../util/text';
import {FormConfig} from '../types';

export const quickstream = {
  title: 'Westpac Quickstream',
  configType: 'biller',

  validation: z.object({
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    publishableKey: z.string().min(1, 'Publishable key is required'),
    secretKey: z.string().min(1, 'Secret key is required'),
    supplierCode: z.string().min(1, 'Supplier code is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'publishableKey',
      label: 'Publishable key',
    },
    {
      type: 'text',
      name: 'secretKey',
      label: 'Secret key',
    },
    {
      type: 'text',
      name: 'supplierCode',
      label: 'Supplier code',
    },
  ],
} satisfies FormConfig;
