import React from 'react';
import {ContactEdge, Contact} from 'lib/graphql/API';
import {ContactTable} from '../components/ContactTable';

type AccountContactsProps = {
  contacts?: Contact[];
};

export const AccountContacts: React.FC<AccountContactsProps> = ({contacts}) => {
  if (!contacts) {
    return <></>;
  }

  const edges = contacts.map(x => {
    return {
      cursor: '',
      __typename: 'ContactEdge',
      node: x,
    };
  }) as ContactEdge[];

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
      <ContactTable
        minimal={true}
        loading={false}
        error={undefined}
        contacts={edges}
      />
    </div>
  );
};
