import React from 'react';
import {PaymentMethod, PaymentMethodStatus} from 'lib/graphql/API';
import {PaymentMethodItem} from 'components/organisms/PaymentMethodItem';
import classNames from 'classnames';

type ContactPaymentMethodsProps = {
  paymentMethods: PaymentMethod[] | null | undefined;
};

export const ContactPaymentMethods: React.FC<ContactPaymentMethodsProps> = ({
  paymentMethods,
}) => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-8">
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {/* Card */}
        {paymentMethods
          ?.filter(pm => pm.status !== PaymentMethodStatus.Removed)
          ?.map(paymentMethod => (
            <div
              key={paymentMethod.id}
              className={classNames(
                'bg-white overflow-hidden shadow rounded-lg p-4',
                paymentMethod.status === 'disabled' ? 'bg-red-50' : ''
              )}
            >
              <PaymentMethodItem paymentMethod={paymentMethod} />
            </div>
          ))}
      </div>
    </div>
  );
};
