import {z} from 'zod';
import {isDomainName} from '../../util/text';
import {FormConfig} from '../types';

export const mastercard_gateway = {
  title: 'Mastercard Gateway',
  configType: 'biller',

  validation: z.object({
    hostname: z.string().refine(isDomainName, {
      message: 'Invalid hostname',
    }),
    merchantId: z.string().min(1, 'Merchant id is required'),
    merchantAccessCode: z.string().min(1, 'Merchant access code is required'),
    tokenizeMerchantId: z.string().min(1, 'Tokenize Merchant id is required'),
    tokenizeMerchantAccessCode: z
      .string()
      .min(1, 'Tokenize Merchant access code is required'),
  }),

  form: [
    {
      type: 'text',
      name: 'hostname',
      label: 'Hostname',
    },
    {
      type: 'text',
      name: 'merchantId',
      label: 'Merchant Id',
    },
    {
      type: 'text',
      name: 'merchantAccessCode',
      label: 'Merchant Access Code',
    },
    {
      type: 'text',
      name: 'tokenizeMerchantId',
      label: 'Tokenize Merchant Id',
    },
    {
      type: 'text',
      name: 'tokenizeMerchantAccessCode',
      label: 'Tokenize Merchant Access Code',
    },
  ],
} satisfies FormConfig;
