import {BanknotesIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {PaymentTable} from 'features/payments/components/PaymentTable';
import {PaymentEdge, useGetPaymentsBySearchLazyQuery} from 'lib/graphql/API';
import React, {useEffect} from 'react';

type PaymentsProps = {
  contactId?: string;
  accountId?: string;
};

export const Payments: React.FC<PaymentsProps> = ({contactId, accountId}) => {
  const [fetch, {data, loading, error}] = useGetPaymentsBySearchLazyQuery({
    variables: {
      accountId,
      contactId,
      offset: 0,
    },
  });
  useEffect(() => {
    fetch();
  }, []);

  const [page, setPage] = React.useState(1);

  const goToPage = (pageNum: number) => {
    fetch({
      variables: {offset: (pageNum - 1) * 10},
    });
    setPage(pageNum);
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  if (!data?.payments?.edges?.length) {
    return (
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <BanknotesIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No payments
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            All payments made for this {accountId ? 'account' : 'contact'} will
            display here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
      <PaymentTable
        payments={data?.payments.edges as PaymentEdge[]}
        isLoading={loading}
        error={error}
        goToPage={goToPage}
        page={page}
        total={data?.payments.total}
      />
    </div>
  );
};
