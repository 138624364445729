import React from 'react';
import {PopOverCtaBottomRow} from 'components/atoms/PopOverCtaBottomRow';

type GenerateInstalmentsProps = {
  onClick: () => void;
  onCancel: () => void;
};

export const GenerateInstalments: React.FC<GenerateInstalmentsProps> = ({
  onClick,
  onCancel,
}) => {
  return (
    <PopOverCtaBottomRow>
      <button
        type="button"
        onClick={() => onCancel()}
        className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Cancel
      </button>
      <button
        type="button"
        onClick={() => onClick()}
        className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Generate plan
      </button>
    </PopOverCtaBottomRow>
  );
};
