import {useMutation} from '@tanstack/react-query';
import axios from 'axios';
import {FormConfigKey, FormConfigData} from 'payble-shared';
import {useToast} from 'payble-ui';

const updateConfig = async <T extends FormConfigKey>(
  data: FormConfigData<T>,
  type?: T
): Promise<FormConfigData<T>> => {
  if (!type) {
    throw new Error(`Can't update without type ${type}`);
  }

  const response = await axios.post(`/api/config/${type}`, data);
  return response.data;
};

const testConfig = async <T extends FormConfigKey>(
  data: FormConfigData<T>,
  type?: T
): Promise<{success: boolean; response: unknown}> => {
  if (!type) {
    throw new Error(`Can't update without type ${type}`);
  }

  const response = await axios.post(
    `/api/config/${type}?test=true' : ''}`,
    data
  );
  return response.data;
};

export const useSaveConfigByType = <T extends FormConfigKey>(type?: T) => {
  const {toast} = useToast();
  return useMutation({
    mutationFn: (data: FormConfigData<T>) => updateConfig(data, type),
    onError: error => {
      toast({
        variant: 'destructive',
        title: 'Failed to save',
        description: error.message,
      });
    },
  });
};

export const useTestConfigByType = <T extends FormConfigKey>(type?: T) => {
  const {toast} = useToast();
  return useMutation({
    mutationFn: (data: FormConfigData<T>) => testConfig(data, type),
    onError: error => {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: error.message,
      });
    },
  });
};
