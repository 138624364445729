import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {FormConfigData, FormConfigKey} from 'payble-shared';

const fetchConfigByType = async <T extends FormConfigKey>(
  type: T
): Promise<FormConfigData<T>> => {
  const response = await axios.get(`/api/config/${type}`);
  return response.data;
};
export const useGetConfigByType = <T extends FormConfigKey>(type?: T) => {
  const query = useQuery({
    queryKey: ['config', type],
    queryFn: () => fetchConfigByType(type as T),
    enabled: !!type,
    retry: 1,
  });

  return query;
};
