import {DocumentTextIcon, FolderIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import React from 'react';
import {DateTime} from 'luxon';
import {ArrowDownTrayIcon} from '@heroicons/react/20/solid';
import {FilePrefix, useGetFilesQuery} from 'lib/graphql/API';

export const ExportsTable: React.FC = () => {
  const {data, loading, error} = useGetFilesQuery({
    pollInterval: 100000,
    variables: {
      prefix: FilePrefix.Export,
    },
  });
  return (
    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              <span className="ml-14">File Name</span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              File Format
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Download
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {loading && (
            <tr key="loading">
              <td
                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 py-16"
                colSpan={4}
              >
                <Loading />
              </td>
            </tr>
          )}
          {error && (
            <tr key="loading">
              <td
                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6 pb-16"
                colSpan={4}
              >
                <ErrorMessage message={error.message} />
              </td>
            </tr>
          )}
          {!loading && !error && data?.files?.length === 0 && (
            <tr key="no-files">
              <td
                className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6"
                colSpan={4}
              >
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="text-center">
                    <FolderIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No files
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Files generated by Payble will display here.
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            !error &&
            data?.files?.map(file => (
              <tr key={file.fileName}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <DocumentTextIcon className="h-10 w-10 rounded-full" />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">
                        {file.fileName}
                      </div>
                      <div className="text-gray-500">
                        {DateTime.fromISO(file.createdAt ?? '').toFormat(
                          'dd MMM yyyy HH:mm:ss'
                        )}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="text-gray-900">Comma Separated Values</div>
                  <div className="text-gray-500">Direct debit report</div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {file.signedUrl && (
                    <a
                      href={file.signedUrl}
                      target="_blank"
                      type="button"
                      className="text-gray-900 cursor-pointer inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-slate-600 bg-slate-50 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-100 mr-1 mt-2"
                    >
                      <ArrowDownTrayIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Download
                    </a>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
