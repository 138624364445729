import React, {useEffect} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {Navbar} from 'features/navbar/Navbar';
import {Skeleton} from 'features/navbar/Skeleton';
import {Audience} from 'features/audience/Audience';
import {Home} from 'features/home/Home';
import {Payments} from 'features/payments/Payments';
import {Login} from 'features/login/Login';
import {Account} from 'features/audience/pages/Account';
import {Contact} from 'features/audience/pages/Contact';
import {ImportsExports} from 'features/import-export/ImportExport';
import {Payer} from 'features/audience/pages/Payer';
import {useLoginFlow} from './lib/auth/useAuthState';
import {PlanRequests} from 'features/audience/pages/PlanRequests';
import {AuditLogs} from './features/audit/pages/AuditLogs';
import {Config} from 'features/config/pages/Config';
import {ConfigTypeManagement} from 'features/config/pages/ConfigTypeManagement';

export const Navigation: React.FC = () => {
  // Maintenance mode is used when we are deploying complex changes
  // and do not want the state of items to change during the PVT.
  // This enables us to roll back to backup taken at the start of deployment.
  // return (
  //   <div className="min-h-screen bg-gray-100">
  //     <Maintenance />
  //   </div>
  // )

  const loginFlow = useLoginFlow();

  useEffect(() => {
    if (loginFlow.type === 'UNINITIALIZED') {
      loginFlow.initialize();
    }
  }, [loginFlow.type]);

  if (loginFlow.type === 'UNINITIALIZED') {
    return <Skeleton />;
  }

  if (loginFlow.type !== 'LOGGED_IN') {
    return <Login />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Router>
        <Navbar />

        <Routes>
          <Route path="/import-export" element={<ImportsExports />} />
          <Route path="/payments/*" element={<Payments />}></Route>
          <Route path="/plan-requests/*" element={<PlanRequests />} />
          <Route path="/audit/*" element={<AuditLogs />} />
          <Route path="/audience" element={<Audience />} />
          <Route path="/audience/account/:lookup/*" element={<Account />} />
          <Route path="/audience/contact/:lookup/*" element={<Contact />} />
          <Route path="/audience/payer/:lookup/*" element={<Payer />} />
          <Route path="/config/:configType" element={<Config />}>
            <Route path=":type" element={<ConfigTypeManagement />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </Router>
    </div>
  );
};
