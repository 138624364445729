import React, {Fragment, ReactChild, useEffect} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {DateTime} from 'luxon';
import {
  InstalmentPlan as InstalmentPlanEntity,
  InstalmentPlanMode,
  useCancelInstalmentPlanMutation,
  useGetInstalmentPlanEventsLazyQuery,
} from 'lib/graphql/API';
import {InstalmentPlanEvent} from './InstalmentPlanEvent';
import NumberFormat from 'react-number-format';
import {Loading} from 'components/atoms/Loading';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {InstalmentTable} from './InstalmentTable';
import {classNames} from 'lib/styles';
import {
  BarsArrowDownIcon,
  CalendarIcon,
  EllipsisHorizontalIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import {CancelModal} from './CancelModal';
import {Spinner} from 'components/atoms/Spinner';
import {PaymentMethodCompact} from './PaymentMethodCompact';
import {formatToDollars} from 'payble-shared/src/core';
import {InstalmentPlanRebalanceDrawer} from './InstalmentPlanRebalanceDrawer';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';
import {OnlyStaff} from '../../../components/OnlyStaff';
import {useCurrentUser} from '../../../lib/auth';

type InstalmentPlanProps = {
  instalmentPlan: InstalmentPlanEntity;
};

export const InstalmentPlan: React.FC<InstalmentPlanProps> = ({
  instalmentPlan,
}) => {
  const {billerConfig} = useCurrentUser();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [mode, setMode] = React.useState<'events' | 'schedule'>('events');
  const canCancel =
    instalmentPlan.status !== 'processing' &&
    instalmentPlan.status !== 'cancelled' &&
    instalmentPlan.status !== 'completed';

  const [refreshEvents, {data, loading, error}] =
    useGetInstalmentPlanEventsLazyQuery({
      variables: {
        id: instalmentPlan.id,
      },
      fetchPolicy: 'network-only',
    });

  const rebalanceD = useDisclosure();

  useEffect(() => {
    refreshEvents();
  }, []);

  const onCancel = () => {
    setShowCancelModal(true);
  };

  const [cancelInstalmentPlan, {loading: cancelLoading, error: cancelError}] =
    useCancelInstalmentPlanMutation({
      variables: {
        input: {
          instalmentPlanId: instalmentPlan.id,
        },
      },
    });

  const onCancelConfirm = async (reason: string) => {
    setShowCancelModal(false);
    await cancelInstalmentPlan({
      variables: {input: {instalmentPlanId: instalmentPlan.id, reason}},
    });
    await refreshEvents();
  };

  if (cancelLoading) {
    return (
      <section
        aria-labelledby="timeline-title"
        className="lg:col-start-3 lg:col-span-2"
      >
        <div className="bg-white shadow sm:rounded-lg ">
          <div className="flex rounded-tl-md rounded-tr-md align-center justify-between bg-slate-50 border-slate-200 z-10 px-4 py-5 sm:px-6 relative border ">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900"
            >
              Payment Plan
              <span className="text-md font-medium ml-2 text-gray-500">
                ({instalmentPlan.account?.externalId})
              </span>
            </h2>
          </div>
          <div className="h-52 flex justify-center items-center">
            <Spinner />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section
      aria-labelledby="timeline-title"
      className="lg:col-start-3 lg:col-span-2"
    >
      <div className="bg-white shadow sm:rounded-lg ">
        <div className="flex rounded-tl-md rounded-tr-md align-center justify-between bg-slate-50 border-slate-200 z-10 px-4 py-5 sm:px-6 relative border ">
          <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
            Payment Plan
            <span className="text-md font-medium ml-2 text-gray-500">
              ({instalmentPlan.account?.externalId})
            </span>
          </h2>
          <Menu as="div" className="relative inline-block text-right">
            <div className="">
              <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500">
                <span className="sr-only">Open options</span>
                <EllipsisHorizontalIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {mode === 'events' && (
                    <Menu.Item>
                      {({active}) => (
                        <span
                          onClick={() => setMode('schedule')}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-500',
                            'px-4 py-2 text-sm flex justify-between cursor-pointer'
                          )}
                        >
                          View schedule
                          <CalendarIcon
                            aria-hidden="true"
                            className="h-5 w-5"
                          />
                        </span>
                      )}
                    </Menu.Item>
                  )}
                  {mode === 'schedule' && (
                    <Menu.Item>
                      {({active}) => (
                        <span
                          onClick={() => setMode('events')}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-500',
                            'px-4 py-2 text-sm flex justify-between cursor-pointer'
                          )}
                        >
                          View events
                          <BarsArrowDownIcon
                            aria-hidden="true"
                            className="h-5 w-5"
                          />
                        </span>
                      )}
                    </Menu.Item>
                  )}
                  {canCancel && (
                    <Menu.Item>
                      {({active}) => (
                        <span
                          onClick={onCancel}
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-500',
                            'px-4 py-2 text-sm flex justify-between cursor-pointer'
                          )}
                        >
                          Cancel
                          <XCircleIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <CancelModal
            headline="Cancel Payment Plan"
            description="Are you sure you want to cancel this payment plan? Please select a reason from the options below:"
            isOpen={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            onReject={onCancelConfirm}
            reasons={[
              'Migrating to a new plan',
              'Customer requested',
              'Last or non-payment',
              'Ownership change',
              'Other',
            ]}
          />
        </div>

        <div className="px-4 py-5 sm:px-6 border-t border-b border-gray-200 divide-y divide-gray-200">
          {cancelError && (
            <div className="rounded-md bg-red-50 p-4 mb-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    There was an error cancelling this payment plan
                  </h3>
                  <div className="mt-2 text-sm text-red-700 break-words">
                    {cancelError.message}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="py-2 flex justify-between text-sm font-medium">
            {instalmentPlan.account?.description
              ? instalmentPlan.account?.description
              : ''}
          </div>
          <dl className=" ">
            {instalmentPlan.paymentMethod && (
              <div className="py-1 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Payment Method</dt>
                <dd>
                  <PaymentMethodCompact
                    paymentMethod={instalmentPlan.paymentMethod}
                  />
                </dd>
              </div>
            )}
            <div className="py-1 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Type</dt>
              <dd className="text-gray-900">
                {getFriendlyPlanName(instalmentPlan.mode)}
              </dd>
            </div>
            {instalmentPlan.frequency && (
              <div className="py-1 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Frequency</dt>
                <dd className="text-gray-900">
                  {toTitleCase(instalmentPlan.frequency)}
                </dd>
              </div>
            )}
            {instalmentPlan.nextInstalmentDueAt && (
              <div className="py-1 flex justify-between text-sm font-medium">
                <dt className="text-gray-500">Due date</dt>
                <dd className="text-gray-900">
                  {!!instalmentPlan.nextInstalmentDueAt &&
                    instalmentPlan.nextInstalmentDueAt
                      .toDateTime({billerConfig})
                      .toLocaleString(DateTime.DATE_MED)}
                </dd>
              </div>
            )}

            <div className="py-1 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Amount Owing</dt>
              <dd className="text-gray-900">
                <NumberFormat
                  value={formatToDollars(instalmentPlan.amount)}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />
              </dd>
            </div>
            <div className="py-1 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Amount Paid</dt>
              <dd className="text-gray-900">
                <NumberFormat
                  value={formatToDollars(instalmentPlan.amountPaid)}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />
              </dd>
            </div>
            <div className="py-1 flex justify-between text-sm font-medium pb-8 border-t-1">
              <dt className="text-gray-500">Amount Outstanding</dt>
              <dd className="text-gray-900">
                <NumberFormat
                  value={formatToDollars(instalmentPlan.amountDue)}
                  displayType={'text'}
                  thousandSeparator={true}
                  decimalSeparator={'.'}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix={'$'}
                />
              </dd>
            </div>
            {instalmentPlan.rebalance.outcome !== 'notPossible' && (
              <OnlyStaff>
                <InstalmentPlanRebalanceDrawer
                  disclosure={rebalanceD}
                  plan={instalmentPlan}
                />
                <button
                  type="button"
                  className="w-full text-gray-900 cursor-pointer px-4 py-2 border border-transparent text-sm font-medium text-slate-600 bg-slate-50 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-100 text-center"
                  onClick={rebalanceD.onToggle}
                >
                  Sync Payment Schedule
                </button>
              </OnlyStaff>
            )}
          </dl>

          {/* Activity Feed */}
          {mode === 'events' && (
            <div className="pt-6 flow-root">
              <ul role="list" className="-mb-8">
                {loading && (
                  <div className="pb-10">
                    <Loading />
                  </div>
                )}
                {error && (
                  <div>
                    <ErrorMessage message={error.message} />
                  </div>
                )}
                {data && data.instalmentPlan && data.instalmentPlan.events && (
                  <>
                    {instalmentPlan.status !== 'completed' &&
                      instalmentPlan.status !== 'cancelled' &&
                      instalmentPlan.status !== 'processing' && (
                        <li key={`${instalmentPlan.id}-next-due`}>
                          <div className="relative pb-8">
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                            {instalmentPlan.nextInstalmentDueAt && (
                              <InstalmentPlanEvent
                                type="next_instalment_due"
                                date={instalmentPlan.nextInstalmentDueAt
                                  .toDateTime({billerConfig})
                                  .toISO()}
                                key={`${instalmentPlan.id}-next-due`}
                                instalmentPlan={instalmentPlan}
                              />
                            )}
                          </div>
                        </li>
                      )}

                    {instalmentPlan.status === 'overdue' &&
                      instalmentPlan.ownerType === 'CONTACT' && (
                        <li key={`${instalmentPlan.id}-overdue`}>
                          <div className="relative pb-8">
                            <span
                              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                              aria-hidden="true"
                            />
                            <InstalmentPlanEvent
                              type="repairing"
                              date={DateTime.local().toISO()}
                              key={`${instalmentPlan.id}-overdue-event`}
                              instalmentPlan={instalmentPlan}
                            />
                          </div>
                        </li>
                      )}

                    {data.instalmentPlan.events
                      .map((event, index) => {
                        // Exclude any admin fix events which are not really for the UI
                        const result: ReactChild[] = [];

                        if (event.type.includes('_fixed')) {
                          return result;
                        }

                        /**
                         * sms are not currently enabled for instalment plan event `instalments_updated`
                         */
                        if (
                          !event.type.includes('instalments_updated') &&
                          instalmentPlan.ownerType === 'CONTACT'
                        ) {
                          result.push(
                            <li key={`${index}-sms`}>
                              <div className="relative pb-8">
                                <span
                                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                  aria-hidden="true"
                                />

                                <InstalmentPlanEvent
                                  type="sms_sent"
                                  date={event.createdAt}
                                  key={`${event.id}-sms`}
                                  instalmentPlan={instalmentPlan}
                                />
                              </div>
                            </li>
                          );
                        }

                        return [
                          ...result,
                          <li key={index}>
                            <div className="relative pb-8">
                              {data.instalmentPlan?.events &&
                                index !==
                                  data.instalmentPlan.events.length - 1 && (
                                  <span
                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                  />
                                )}
                              <InstalmentPlanEvent
                                type={event.type}
                                date={event.createdAt}
                                key={event.id}
                                instalmentPlan={instalmentPlan}
                              />
                            </div>
                          </li>,
                        ];
                      })
                      .flat()}
                  </>
                )}
              </ul>
            </div>
          )}

          {/* Schedule */}
          {mode === 'schedule' && (
            <div className="pt-6 flow-root">
              <InstalmentTable instalmentPlan={instalmentPlan} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function __getFriendlyEventName(eventType: string) {
  const base = 'instalment_plan';
  switch (eventType) {
    case `${base}.instalment_missed`:
      return 'Missed instalment';
    case `${base}.instalment_skipped`:
      return 'Skipped instalment';
    case `${base}.instalment_payment_succeeded`:
      return 'Instalment paid';
    case `${base}.instalment_payment_failed`:
      return 'Instalment payment failed';
    case `${base}.instalment_payment_processing`:
      return 'Payment processing';
    default:
      return toTitleCase(eventType.replace(`${base}.`, ''));
  }
}

function getFriendlyPlanName(mode: InstalmentPlanMode) {
  switch (mode) {
    case InstalmentPlanMode.PayOnY:
      return 'Scheduled one-off';
    case InstalmentPlanMode.PayXOnY:
      return 'Autopay';
    case InstalmentPlanMode.PayXEveryZ:
      return 'Flexible';
    case InstalmentPlanMode.PayEveryX:
      return 'Flexible';
    default:
      return 'Flexible';
  }
}
