import React from 'react';

import {useGetPaymentStatsQuery} from 'lib/graphql/API';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import classNames from 'classnames';
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from '@heroicons/react/24/outline';

type Props = {
  paymentCount?: number;
  instalmentCount?: number;
  repairCount?: number;
  isLoading: boolean;
};

// Format number as string
const formatNumber = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Get percentage change between two numbers
const getPercentageChange = (oldValue: number, newValue: number) => {
  if (oldValue === 0 && newValue === 0) {
    return '-';
  }
  if (oldValue === 0) {
    oldValue = 1;
    newValue += 1;
  }

  const change = newValue - oldValue;
  const percentage = (change / oldValue) * 100;
  return `${percentage.toFixed(0)}%`;
};

export const PaymentStats: React.FC<Props> = () => {
  const {
    data: statsData,
    loading: statsLoading,
    error: statsError,
  } = useGetPaymentStatsQuery();

  const stats: {
    name: string;
    stat: string;
    previousStat: string;
    change: string;
    changeType: string;
    increaseNegative: boolean;
  }[] = !statsData
    ? []
    : [
        {
          name: 'Total Successful',
          stat: formatNumber(statsData.paymentStats.totalSuccessful),
          previousStat: formatNumber(
            statsData.paymentStats.totalSuccessfulLastMonth
          ),
          change: getPercentageChange(
            statsData.paymentStats.totalSuccessfulLastMonth,
            statsData.paymentStats.totalSuccessful
          ),
          changeType:
            statsData.paymentStats.totalSuccessful >=
            statsData.paymentStats.totalSuccessfulLastMonth
              ? 'increase'
              : 'decrease',
          increaseNegative: false,
        },
        {
          name: 'Total Failed',
          stat: formatNumber(statsData.paymentStats.totalFailed),
          previousStat: formatNumber(
            statsData.paymentStats.totalFailedLastMonth
          ),
          change: getPercentageChange(
            statsData.paymentStats.totalFailedLastMonth,
            statsData.paymentStats.totalFailed
          ),
          changeType:
            statsData.paymentStats.totalFailed >=
            statsData.paymentStats.totalFailedLastMonth
              ? 'increase'
              : 'decrease',
          increaseNegative: true,
        },
        {
          name: 'Total Scheduled',
          stat: formatNumber(statsData.paymentStats.totalScheduled),
          previousStat: formatNumber(
            statsData.paymentStats.totalScheduledLastMonth
          ),
          change: getPercentageChange(
            statsData.paymentStats.totalScheduledLastMonth,
            statsData.paymentStats.totalScheduled
          ),
          changeType:
            statsData.paymentStats.totalScheduled >=
            statsData.paymentStats.totalScheduledLastMonth
              ? 'increase'
              : 'decrease',
          increaseNegative: false,
        },
      ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-10">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Last 30 days
      </h3>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        {statsError ? (
          <div className="flex justify-center col-span-3 pb-10">
            <ErrorMessage message={statsError.message} />
          </div>
        ) : null}
        {statsLoading ? (
          <div className="flex justify-center col-span-3 py-10">
            <Loading />
          </div>
        ) : null}
        {!statsLoading &&
          !statsError &&
          stats.map(item => (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {item.name}
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-payble-violet">
                  {item.stat}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    from {item.previousStat}
                  </span>
                </div>

                <div
                  className={classNames(
                    item.changeType === 'increase' &&
                      item.increaseNegative === false
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  {item.changeType === 'increase' ? (
                    <ArrowSmallUpIcon
                      className={classNames(
                        '-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ',
                        item.increaseNegative
                          ? 'text-red-500'
                          : 'text-green-500'
                      )}
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowSmallDownIcon
                      className={classNames(
                        '-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5',
                        !item.increaseNegative
                          ? 'text-red-500'
                          : 'text-green-500'
                      )}
                      aria-hidden="true"
                    />
                  )}

                  <span className="sr-only">
                    {item.changeType === 'increase' ? 'Increased' : 'Decreased'}{' '}
                    by
                  </span>
                  {item.change}
                </div>
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
};
