import * as mapper from './mapper';
import {FormConfigKey} from './types';

export const findFormConfig = <T extends FormConfigKey>(type: T) => {
  const config = mapper[type];

  if (!config) {
    throw new Error(`Config type ${type} not found`);
  }

  return config;
};
