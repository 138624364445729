import React, {Fragment, useEffect, useState} from 'react';
import {Disclosure, Menu, Transition} from '@headlessui/react';
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline';
import {NavLink, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {LockClosedIcon} from '@heroicons/react/24/outline';
import {getNavbarItems} from './helpers';
import {auth} from '../../lib';
import {useLocalStorageSync} from 'lib/hooks/useLocalStorageSync';
import {OnlyStaff} from 'components/OnlyStaff';
import {Link} from 'react-router-dom';

export const Navbar: React.FC = () => {
  const location = useLocation();

  const loginFlow = auth.useLoginFlow();

  const signOut = () => {
    if (loginFlow.type === 'LOGGED_IN') {
      loginFlow.logout();
    }
  };

  const {billerSlug: slug} = auth.useCurrentUser();
  const [currentBiller, setcurrentBiller] = useState(slug);
  const [store, setStore] = useLocalStorageSync('biller', slug);

  useEffect(() => {
    setcurrentBiller(slug);
    setStore(slug);
  }, [slug]);

  useEffect(() => {
    if (store !== currentBiller) {
      window.location.reload();
    }
  }, [store]);

  const navigation = getNavbarItems(slug);

  return (
    <Disclosure as="nav" className="bg-white shadow-sm">
      {({open}) => (
        <>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex justify-between flex-grow">
                <div className="flex">
                  <div className="flex items-center flex-shrink-0">
                    <NavLink to={'/'}>
                      <img
                        className="hidden w-auto h-8 lg:block"
                        src="/cms/payble-logo.png"
                        alt="Payble"
                      />
                    </NavLink>
                  </div>
                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map(
                      item =>
                        (item.disabled && (
                          <div
                            key={item.name}
                            className={classNames(
                              'border-transparent text-gray-500 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold cursor-not-allowed opacity-50 hover:border-b-2  hover:text-gray-500'
                            )}
                          >
                            {item.name}
                            <LockClosedIcon className="inline-block w-5 h-5 ml-2" />
                          </div>
                        )) ||
                        (!item.isExternal && !item.disabled && (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              'border-transparent text-gray-500 hover:text-navy hover:border-payble-coral/50',
                              'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold',
                              location.pathname.startsWith(item.href)
                                ? 'border-payble-coral text-navy'
                                : ''
                            )}
                          >
                            {item.name}
                          </NavLink>
                        )) ||
                        (item.isExternal && !item.disabled && (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              'border-transparent text-gray-500 hover:text-navy hover:border-payble-coral/50',
                              'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold',
                              location.pathname.startsWith(item.href)
                                ? 'border-payble-coral text-navy'
                                : ''
                            )}
                          >
                            {item.name}
                          </a>
                        ))
                    )}
                  </div>
                </div>
              </div>
              <div className="justify-end hidden w-56 sm:ml-6 sm:flex sm:items-center">
                <img
                  src={
                    slug
                      ? `/cms/biller/${slug}/logo.png`
                      : '/cms/payble-logo.png'
                  }
                  className="mr-2 h-1/2"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-payble-coral">
                      <span className="sr-only">Open user menu</span>
                      <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                        <svg
                          className="w-full h-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <OnlyStaff>
                        <Menu.Item key="profile">
                          <Link
                            to="/config/biller"
                            className={classNames(
                              'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200'
                            )}
                          >
                            Manage Config
                          </Link>
                        </Menu.Item>
                      </OnlyStaff>
                      <Menu.Item key="profile">
                        <div
                          onClick={signOut}
                          className={classNames(
                            'block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-200'
                          )}
                        >
                          Sign out
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="flex items-center -mr-2 sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map(item => (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                    'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                    location.pathname.includes(item.name)
                      ? 'border-blue-600 text-navy'
                      : ''
                  )}
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                    <svg
                      className="w-full h-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <div
                  onClick={signOut}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                >
                  Sign out
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
