import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {
  Account,
  Contact as ContactFragment,
  useGetContactQuery,
} from 'lib/graphql/API';
import React, {Fragment} from 'react';
import {
  matchPath,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {err, getBillerConfig} from 'payble-shared';
import {Loading} from 'components/atoms/Loading';
import {
  EllipsisHorizontalIcon,
  ShieldCheckIcon,
  BanknotesIcon,
  ChatBubbleLeftIcon,
} from '@heroicons/react/24/outline';
import {Breadcrumbs} from 'components/atoms/Breadcrumbs';
import classNames from 'classnames';
import {Tabs} from 'components/atoms/Tabs';
import {ContactAccounts} from './ContactAccounts';
import {ContactPaymentMethods} from './ContactPaymentMethods';
import {InstalmentPlans} from './InstalmentPlans';
import {Payments} from './Payments';
import {Menu, Transition} from '@headlessui/react';
import {AddPaymentMethodForm} from '../forms/AddPaymentMethodForm';
import {useDisclosure} from 'lib/hooks/useDisclosure';
import {AddInstalmentPlanForm} from '../forms/AddInstalmentPlanForm';
import {auth} from 'lib/index';
import {AddAutoPayForm} from '../forms/AddAutoPayForm';
import {SendNotificationForm} from '../forms/SendNotificationForm';
import {Notifications} from './Notifications';
import {Requests} from './Requests';
import {CreatePlansMenuOptions} from '../components/CreatePlansMenuOptions';
import {PhoneNumberFormat} from 'payble-app-shared/src/components/PhoneNumberFormat';

const getContactFriendlyName = (contact: ContactFragment) => {
  const friendlyName = `${contact.givenName} ${contact.familyName}`.trim();
  if (friendlyName !== '') {
    return friendlyName;
  }

  if (contact.email !== '') {
    return contact.email;
  }

  return contact.mobile;
};

const getVariables = (lookup: string) => {
  if (lookup.startsWith('mobile:')) {
    // Do a query based on the mobile
    return {
      mobile: lookup.replace('mobile:', ''),
    };
  }

  if (lookup.startsWith('email:')) {
    // Do a query based on the email
    return {
      email: lookup.replace('email:', ''),
    };
  }

  if (lookup.startsWith('externalId:')) {
    // Do a query based on the externalId
    return {
      externalId: lookup.replace('externalId:', ''),
    };
  }
  return {
    id: lookup,
  };
};

export const Contact: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {lookup} = useParams<{lookup: string}>();
  const {billerSlug: slug} = auth.useCurrentUser();
  const billerConfig = getBillerConfig(slug);

  const addPaymentMethodDisclosure = useDisclosure();
  const addInstalmentPlanFormDisclosure = useDisclosure();
  const enableAutoPayDisclosure = useDisclosure();
  const sendNotificationDisclosure = useDisclosure();

  if (!lookup) return <ErrorMessage message="No lookup provided" />;

  const variables = getVariables(lookup);
  if (err(variables)) return <ErrorMessage message={variables.message} />;

  const {data, loading, error} = useGetContactQuery({
    variables,
  });

  return (
    <>
      {loading ? <Loading /> : null}
      {error ? <ErrorMessage message={error.message} /> : null}
      {!loading && !error && data && data.contact ? (
        <div>
          <div className="mt-8">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <Breadcrumbs
                pages={[
                  {name: 'Audience', href: '/audience'},
                  {
                    name: getContactFriendlyName(
                      data.contact as ContactFragment
                    ),
                    href: `/audience/contact/${data.contact.id}`,
                    current: true,
                  },
                ]}
              />

              <div className="bg-white shadow sm:rounded-lg mt-8">
                <div className="flex justify-between">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Contact Information
                    </h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Details and contact information.
                    </p>
                  </div>
                  <Menu
                    as="div"
                    className="relative top-5 right-6 inline-block text-right"
                  >
                    <div className="">
                      <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() =>
                                  addPaymentMethodDisclosure.onOpen()
                                }
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-500',
                                  'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                )}
                              >
                                Add Payment Method
                                <BanknotesIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </button>
                            )}
                          </Menu.Item>
                          <CreatePlansMenuOptions
                            billerConfig={billerConfig}
                            onOpenInstalmentPlanForm={() =>
                              addInstalmentPlanFormDisclosure.onOpen()
                            }
                            onOpenEnableAutoPay={() =>
                              enableAutoPayDisclosure.onOpen()
                            }
                          />
                          <Menu.Item>
                            {({active}) => (
                              <button
                                onClick={() =>
                                  sendNotificationDisclosure.onOpen()
                                }
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-500',
                                  'px-4 py-2 text-sm flex justify-between cursor-pointer w-full'
                                )}
                              >
                                Send notification
                                <ChatBubbleLeftIcon
                                  aria-hidden="true"
                                  className="h-5 w-5"
                                />
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <AddPaymentMethodForm
                    contactId={data.contact.id}
                    disclosure={addPaymentMethodDisclosure}
                  />
                  <AddInstalmentPlanForm
                    contactId={data.contact.id}
                    disclosure={addInstalmentPlanFormDisclosure}
                    paymentMethods={data.contact.paymentMethods}
                  />
                  <AddAutoPayForm
                    contactId={data.contact.id}
                    paymentMethods={data.contact.paymentMethods}
                    disclosure={enableAutoPayDisclosure}
                  />
                  <SendNotificationForm
                    contactId={data.contact.id}
                    disclosure={sendNotificationDisclosure}
                  />
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Given name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.contact.givenName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Family name
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.contact.familyName}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 truncate ">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            data.contact.emailVerified
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <a href={`mailto:${data.contact.email}`} className="">
                          {data.contact.email}
                        </a>
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Mobile
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        <ShieldCheckIcon
                          className={classNames(
                            'inline-flex mr-2 h-5 w-5',
                            data.contact.mobileVerified
                              ? 'text-green-400'
                              : 'text-gray-400'
                          )}
                          aria-hidden="true"
                        />
                        <PhoneNumberFormat
                          value={data.contact.mobile}
                          region={billerConfig.region}
                        />
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <Tabs
                tabs={[
                  {
                    name: 'Payment Plans',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/instalment-plans`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/instalment-plans',
                        location.pathname
                      ) !== null ||
                      matchPath(
                        '/audience/contact/:lookup',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Payments',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/payments`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/payments',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Payment Methods',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/payment-methods`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/payment-methods',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Accounts',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/accounts`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/accounts',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Notifications',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/notifications`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/notifications',
                        location.pathname
                      ) !== null,
                  },
                  {
                    name: 'Requests',
                    onClick: () => {
                      navigate(`/audience/contact/${lookup}/requests`, {
                        state: {lookup},
                      });
                    },
                    current:
                      matchPath(
                        '/audience/contact/:lookup/requests',
                        location.pathname
                      ) !== null,
                  },
                ]}
              />
            </div>
          </div>

          <div className="mt-8">
            <Routes>
              <Route
                path=""
                element={<InstalmentPlans contactId={data.contact.id} />}
              />
              <Route
                path="accounts"
                element={
                  <ContactAccounts
                    accounts={data.contact.accounts as Account[]}
                  />
                }
              />
              <Route
                path="payment-methods"
                element={
                  <ContactPaymentMethods
                    paymentMethods={data.contact.paymentMethods}
                  />
                }
              />
              <Route
                path="instalment-plans"
                element={<InstalmentPlans contactId={data.contact.id} />}
              />
              <Route
                path="payments"
                element={<Payments contactId={data.contact.id} />}
              />
              <Route
                path="notifications"
                element={<Notifications contactId={data.contact.id} />}
              />
              <Route
                path="requests"
                element={<Requests contactId={data.contact.id} />}
              />
            </Routes>
          </div>
        </div>
      ) : null}
    </>
  );
};
