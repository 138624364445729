import {BellAlertIcon} from '@heroicons/react/24/outline';
import {ErrorMessage} from 'components/atoms/ErrorMessage';
import {Loading} from 'components/atoms/Loading';
import {NotificationTable} from 'features/notifications/components/NotificationTable';
import {
  NotificationEdge,
  useGetNotificationsBySearchLazyQuery,
} from 'lib/graphql/API';
import React, {useEffect} from 'react';

type NotificationsProps = {
  contactId: string;
};

export const Notifications: React.FC<NotificationsProps> = ({contactId}) => {
  const [fetch, {data, loading, error}] = useGetNotificationsBySearchLazyQuery({
    variables: {
      contactId,
      offset: 0,
    },
  });

  useEffect(() => {
    fetch();
  }, []);

  const [page, setPage] = React.useState(1);

  const goToPage = (pageNum: number) => {
    fetch({
      variables: {contactId, offset: (pageNum - 1) * 10},
    });
    setPage(pageNum);
  };

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ErrorMessage message={error.message} />
      </div>
    );
  }

  if (!data?.notifications?.edges?.length) {
    return (
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <BellAlertIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No notifications
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            All notifications sent to this contact will be displayed here.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 -mt-12">
      <NotificationTable
        notifications={data?.notifications.edges as NotificationEdge[]}
        isLoading={loading}
        error={error}
        goToPage={goToPage}
        page={page}
        total={data?.notifications.total}
      />
    </div>
  );
};
