import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';
import {
  PaymentMode,
  PaymentEdge,
  useGetPaymentsBySearchLazyQuery,
  ScheduledPaymentEdge,
  useGetScheduledPaymentsLazyQuery,
} from 'lib/graphql/API';
import React, {useEffect} from 'react';

import {NavLink, Routes, Route, useLocation} from 'react-router-dom';
import {PaymentStats} from './components/PaymentStats';
import classNames from 'classnames';
import {ScheduledPaymentTable} from './components/ScheduledPaymentTable';
import {useDebouncedCallback} from 'use-debounce';
import {PaymentTable} from './components/PaymentTable';

const getModeFromRoute = (route: string) => {
  switch (route) {
    case '/payments/full':
      return [PaymentMode.PayInFull, PaymentMode.PayAmount];
    case '/payments/plans':
      return [
        PaymentMode.PayInstalment,
        PaymentMode.PayInstalmentPlanRemainder,
      ];
    case '/payments/all':
    default:
      return [
        PaymentMode.PayInFull,
        PaymentMode.PayAmount,
        PaymentMode.PayInstalment,
        PaymentMode.PayInstalmentPlanRemainder,
      ];
  }
};

export const Payments: React.FC = () => {
  const location = useLocation();
  const search = React.useRef<string>();

  const [fetch, {data, loading, error}] = useGetPaymentsBySearchLazyQuery({
    variables: {
      mode: getModeFromRoute(location.pathname),
    },
  });
  const [allPage, setAllPage] = React.useState(1);
  const [fullPage, setFullPage] = React.useState(1);
  const [instalmentPlansPage, setInstalmentPlansPage] = React.useState(1);
  const [scheduledPage, setScheduledPage] = React.useState(1);
  const [__isTyping, setIsTyping] = React.useState(false);

  const [
    fetchScheduledPaymnets,
    {
      data: scheduledPaymentsData,
      loading: scheduledPaymentLoading,
      error: scheduledPaymentsError,
    },
  ] = useGetScheduledPaymentsLazyQuery({});

  // Trigger empty search on load
  useEffect(() => {
    let page = 1;
    if (
      location.pathname.includes('/all') ||
      location.pathname.endsWith('payments/')
    ) {
      page = allPage;
    } else if (location.pathname.includes('/full')) {
      page = fullPage;
    } else if (location.pathname.includes('/plans')) {
      page = instalmentPlansPage;
    } else if (location.pathname.includes('/scheduled')) {
      page = scheduledPage;

      fetchScheduledPaymnets({
        variables: {
          offset: (page - 1) * 10,
          search: search.current,
        },
      });
      return;
    }
    fetch({
      variables: {
        offset: (page - 1) * 10,
        search: search.current,
      },
    });
  }, [location.pathname]);

  const goToPage = (pageNum: number) => {
    if (location.pathname.includes('/scheduled')) {
      fetchScheduledPaymnets({
        variables: {offset: (pageNum - 1) * 10, search: search.current},
      });
      setScheduledPage(pageNum);
    } else {
      fetch({
        variables: {offset: (pageNum - 1) * 10, search: search.current},
      });
      if (
        location.pathname.includes('/all') ||
        location.pathname === '/payments'
      ) {
        setAllPage(pageNum);
      } else if (location.pathname.includes('/full')) {
        setFullPage(pageNum);
      } else {
        setInstalmentPlansPage(pageNum);
      }
    }
  };

  // Wait until they finish typing before making request
  const debouncedSearch = useDebouncedCallback(
    value => {
      if (location.pathname === '/payments/scheduled') {
        fetchScheduledPaymnets({
          variables: {
            search: value,
            // first: 10,
          },
        });
        setScheduledPage(1);
      } else {
        fetch({
          variables: {
            search: value,
            // first: 10,
          },
        });
        if (
          location.pathname.includes('/all') ||
          location.pathname.endsWith('payments/')
        ) {
          setAllPage(1);
        } else if (location.pathname.includes('/full')) {
          setFullPage(1);
        } else {
          setInstalmentPlansPage(1);
        }
      }

      search.current = value;
      setIsTyping(false);
    },
    // delay in ms
    500
  );

  return (
    <div className="py-10">
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <PaymentStats
          paymentCount={data?.payments?.total}
          instalmentCount={0}
          repairCount={0}
          isLoading={loading}
        />

        <header>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight text-gray-900">
              Payments
            </h1>
          </div>
        </header>

        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                defaultValue={'Direct Debit'}
              >
                <option key="Direct Debit">Direct Debit</option>
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200 flex justify-between">
                <nav className="-mb-px flex" aria-label="Tabs">
                  <NavLink
                    key={'all'}
                    to={'all'}
                    className={classNames(
                      'whitespace-nowrap py-4 px-12 border-b-2 font-semibold text-sm cursor-pointer transition-all text-center',
                      location.pathname === '/payments' ||
                        location.pathname === '/payments/all'
                        ? 'border-payble-violet text-payble-violet'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    )}
                  >
                    All
                  </NavLink>
                  <NavLink
                    key={'debit'}
                    to={'full'}
                    className={classNames(
                      'whitespace-nowrap py-4 px-12 border-b-2 font-semibold text-sm cursor-pointer transition-all',
                      location.pathname === '/payments/full'
                        ? 'border-payble-violet text-payble-violet'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    )}
                  >
                    One-off payments
                  </NavLink>
                  <NavLink
                    to={'plans'}
                    key={'plans'}
                    className={classNames(
                      'whitespace-nowrap py-4 px-12 border-b-2 font-semibold text-sm cursor-pointer transition-all',
                      location.pathname === '/payments/plans'
                        ? 'border-payble-violet text-payble-violet'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    )}
                  >
                    Payment plans
                  </NavLink>
                  <NavLink
                    to={'scheduled'}
                    key={'scheduled'}
                    className={classNames(
                      'whitespace-nowrap py-4 px-12 border-b-2 font-semibold text-sm cursor-pointer transition-all',
                      location.pathname === '/payments/scheduled'
                        ? 'border-payble-violet text-payble-violet'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    )}
                  >
                    Scheduled <span className="font-semibold">(30 days)</span>
                  </NavLink>
                </nav>
                <div className="mt-2 relative rounded-md mr-2">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-blue-500 focus:border-blue-500 block pl-10 sm:text-sm border-gray-300 rounded-md w-96"
                    placeholder="Search payments"
                    autoFocus={true}
                    onChange={e => {
                      setIsTyping(true);
                      debouncedSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <Routes>
            <Route
              path="all"
              element={
                <PaymentTable
                  payments={data?.payments.edges as PaymentEdge[]}
                  isLoading={loading}
                  error={error}
                  goToPage={goToPage}
                  page={allPage}
                  total={data?.payments.total}
                />
              }
            />

            <Route
              path={'full'}
              element={
                <PaymentTable
                  payments={data?.payments.edges as PaymentEdge[]}
                  isLoading={loading}
                  error={error}
                  goToPage={goToPage}
                  page={fullPage}
                  total={data?.payments.total}
                />
              }
            />

            <Route
              path={'plans'}
              element={
                <PaymentTable
                  payments={data?.payments.edges as PaymentEdge[]}
                  isLoading={loading}
                  error={error}
                  goToPage={goToPage}
                  page={instalmentPlansPage}
                  total={data?.payments.total}
                />
              }
            />

            <Route
              path={'scheduled'}
              element={
                <ScheduledPaymentTable
                  payments={
                    scheduledPaymentsData?.scheduledPayments
                      ?.edges as ScheduledPaymentEdge[]
                  }
                  isLoading={scheduledPaymentLoading}
                  error={scheduledPaymentsError}
                  goToPage={goToPage}
                  page={scheduledPage}
                  total={scheduledPaymentsData?.scheduledPayments.total}
                />
              }
            />
            {/* Fallback */}
            <Route
              path=""
              element={
                <PaymentTable
                  payments={data?.payments.edges as PaymentEdge[]}
                  isLoading={loading}
                  error={error}
                  goToPage={goToPage}
                  page={allPage}
                  total={data?.payments.total}
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};
