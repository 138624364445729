import {z} from 'zod';
import {intFromString} from '../../util/text';
import {FormConfig} from '../types';

export const utilibill = {
  title: 'UtiliBill Integration',
  configType: 'biller',

  validation: z.object({
    username: z.string().min(1),
    password: z.string().min(1),
    apiBaseURL: z.string().url(),
    maxGroupNumber: z
      .string()
      .transform(intFromString)
      .pipe(z.number().positive())
      .or(z.number().positive()),
    dishonourFeeAmount: z
      .string()
      .transform(s => (s === '' ? null : s))
      .transform(s => (s ? intFromString(s) : null))
      .pipe(z.number().positive().nullable())
      .or(z.number().positive().nullable()),
  }),

  form: [
    {
      label: 'API Base URL',
      name: 'apiBaseURL',
      type: 'text',
      placeholder: 'https://billeruat.utilibill.com.au/biller',
      description:
        'Typically looks like "https://billeruat.utilibill.com.au/biller"',
    },
    {
      label: 'Username/Client ID',
      name: 'username',
      type: 'text',
      placeholder: 'Payble',
    },
    {
      label: 'Password/Client Secret',
      name: 'password',
      type: 'password',
      placeholder: 'Password',
    },
    {
      label: 'Max Group Number',
      name: 'maxGroupNumber',
      type: 'number',
      placeholder: '150',
      description:
        'There is no way to detect the number of "customer groups" in utilibill programatically. This is the maximum number of groups we will search for customers in. If customers do not show up after sync increase this number.',
    },
    {
      label: 'Dishonour Fee Amount (In Cents)',
      name: 'dishonourFeeAmount',
      type: 'number',
      placeholder: '0',
      description:
        'The amount to charge customers when their bank account direct debit payment fails. Leaving blank will send no fee.',
    },
  ],
} satisfies FormConfig;
